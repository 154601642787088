import type { UserAccountDTO } from "$lib/server/entities/user_account/UserAccountEntity";
import type { FollowsViewData } from "$lib/server/follower/follower_service";
import { differenceWith, intersectionWith, uniqWith } from "lodash-es";

const EQUALITY_CMP = (a:UserAccountDTO, b:UserAccountDTO) => a.uuid === b.uuid;

class FollowsStore {

    private _following_user_accounts: UserAccountDTO[] = $state([]);
    private _followed_user_accounts: UserAccountDTO[] = $state([]);
    private _candidate_user_accounts: UserAccountDTO[] = $state([]);
    private _all_user_accounts: UserAccountDTO[] = $derived.by(() => {
        
        // console.log('All User Accounts re-evaluated');
        let to_return = [
                            ...this._following_user_accounts,
                            ...this._followed_user_accounts,
                            ...this._candidate_user_accounts
                        ];
        return uniqWith(to_return, EQUALITY_CMP);
    });

    private _friends: UserAccountDTO[] = $derived.by(() => {
    
        // console.log('Friends re-evaluated');
        let to_return = intersectionWith(this._following_user_accounts, 
                                         this._followed_user_accounts, 
                                         EQUALITY_CMP);
        return to_return;

    });
    private _following: UserAccountDTO[] = $derived.by(() => {

        // console.log('Following re-evaluated');
        let to_return = differenceWith(this._following_user_accounts, 
                                       this._friends, 
                                       EQUALITY_CMP);
        return to_return;
    });
    private _followed: UserAccountDTO[] = $derived.by(() => {

        // console.log('Followed re-evaluated');
        let to_return = differenceWith(this._followed_user_accounts, 
                                       this._friends, 
                                       EQUALITY_CMP);
        return to_return;
    });
    private _candidates: UserAccountDTO[] = $derived.by(() => {

        // console.log('Candidates re-evaluated');
        let to_return = differenceWith(this._all_user_accounts, 
                                       this._following_user_accounts,
                                       EQUALITY_CMP);
        return to_return;
    });

    initialize(view_data: FollowsViewData) {

        // console.log("FollowsStore.initialize: ", view_data);

        this._following_user_accounts = view_data.following_user_accounts;
        this._followed_user_accounts = view_data.followed_user_accounts;
        this._candidate_user_accounts = view_data.candidate_user_accounts;
    }

    private find_user_account(uuid: string, user_accounts: UserAccountDTO[]): UserAccountDTO | undefined {
        return user_accounts.find(item => item.uuid === uuid);
    }

    get friends(): UserAccountDTO[] {
        return this._friends;
    }

    get following(): UserAccountDTO[] {
        return this._following;
    }

    get followed(): UserAccountDTO[] {
        return this._followed;
    }

    get candidates(): UserAccountDTO[] {
        return this._candidates;
    }

    follow(user_account: UserAccountDTO) {
        // console.log("FollowsStore.follow: ", user_account.uuid);

        let existing_following = this.find_user_account(user_account.uuid, this._following_user_accounts);
        if (existing_following) {
            return;
        }

        this._following_user_accounts.push(user_account!);
    }

    unfollow(user_account_uuid: string) {
        // console.log("FollowsStore.unfollow: ", user_account_uuid);

        let user_account: UserAccountDTO | undefined = 
            this.find_user_account(user_account_uuid, this._all_user_accounts);

        // remove from 'following'
        this._following_user_accounts = this._following_user_accounts.filter((item) => item.uuid !== user_account!.uuid);
    }

    get_current_status(user_accoun_uuid: string): 'friend' | 'following' | 'followed' | 'candidate' {

        if (this.find_user_account(user_accoun_uuid, this._friends)) {
            return 'friend';
        }
        
        if (this.find_user_account(user_accoun_uuid, this._following)) {
            return 'following';
        }
        
        if (this.find_user_account(user_accoun_uuid, this._followed)) {
            return 'followed';
        }
        
        if (this.find_user_account(user_accoun_uuid, this._candidates)) {
            return 'candidate';
        }

        return 'candidate';
    }

    is_following(user_account_uuid: string): boolean {
        // return ['following', 'friend'].includes(this.get_current_status(user_account_uuid));

        // this._following_user_accounts accounts for both 'following' and 'friends'
        return this.find_user_account(user_account_uuid, this._following_user_accounts) !== undefined;
    }

    add_candidates(candidates: UserAccountDTO[]) {
        this._candidate_user_accounts.push(...candidates);
    }

    set_candidates(candidates: UserAccountDTO[]) {
        this._candidate_user_accounts = candidates;
    }
}

const follows_store = new FollowsStore();
export default follows_store;